$white: #ffffff;

$brand-0: #003a79;
$brand-1: #011e4c;
$brand-2: #07689f;
$brand-3: #379ac5;
$brand-4: #60c3e2;

$black-0: #1b2b4b;
$black-1: #303030;
$black-2: #828282;
$black-3: #c0c0c0;
$black-4: #17090e;

$green-0: #149b14;
$green-1: #29b51c;
$green-2: #63d24d;
$green-3: #90e872;
$green-4: #f4fbf4;
$green-5: #eaf8e8;
$green-6: #eef9ed;
$green-7: #e5e5e5;
$green-8: #90e872;

$blue-0: #008ed1;
$blue-1: #006eb3;
$blue-2: #07689f;
$blue-3: #38b7e3;
$blue-6: #ecf5f9;
$blue-7: #f5fafd;

$red-1: #f0142f;
$red-2: #fa7970;
$red-3: #fbece9;
$red-4: #fdf3f5;
$red-5: #fcf0ed;

$dark-red-1: #ce0e37;
$dark-red-2: #f64c52;

$gray-1: #131523;
$gray-2: #333752;
$gray-3: #5a607f;
$gray-4: #7e84a3;
$gray-5: #a1a7c4;
$gray-6: #d7dbec;
$gray-7: #f5f6fa;
$gray-8: #efefef;
$gray-9: #eaeaea;
$gray-10: #f4f4f4;
$gray-11: #fafafa;
$gray-12: #e6e9f4;
$gray-13: #eeeeee;
$gray-14: #ece9f1;

$orange-0: #db6a0e;
$orange-1: #ff8914;
$orange-2: #ffc672;
$orange-3: #ffaf4e;
$orange-4: #fffbf6;
$orange-5: #fef7e5;
$orange-6: #fff9eb;

$purple-1: #a75ef1;

$seeding-color: #1976d2;
$assessment-color: #8404ae;
$maintenance-color: #938700;
$inventory_seed-color: #6d4c41;
$harvest-color: #385a24;
$seed_manage-color: #d97706;
$tag_log-color: #334155;
$refuel-color: #0f766e;
