.boats-page {
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  max-width: 1200px;

  .add-button {
    display: flex;
    justify-content: flex-end;
    margin-top: -10px;
    margin-bottom: 10px;
  }
  .boat-wrapper {
    border: 1px solid #b3b3b3;
    margin: 10px 0px;
    display: flex;
    padding: 10px;
    background-color: #ffffff;

    .edit-btn {
      width: 35px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border: none;
      border-radius: 5px;
    }
    .delete-btn {
      width: 35px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
      cursor: pointer;
      border: none;
      border-radius: 5px;
    }
    .--label {
      font-size: 14px;
      color: #7b7b7b;
    }
    .--value {
      font-size: 18px;
      color: #181b1f;
      font-weight: 700;
    }
    .boat-info {
      flex: 1;
    }
    .crew-info {
      flex: 3;
      border-left: 0.5px solid #cac8c8;
      padding-left: 10px;
      margin-left: 5px;
      position: relative;

      .update-btn {
        position: absolute;
        right: 0;
        bottom: 0;
        border: none;
        background-color: #008ed1;
        color: white;
        border-radius: 3px;
        font-weight: 500;
        padding: 3px 10px;
        cursor: pointer;
      }
      button.disabled {
        background-color: #bab5b5;
      }
    }
  }
}
