@import '../../styles/variables';

.calendar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  flex-wrap: wrap;

  .current-month {
    color: #17090e !important;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: -0.01em;
    width: 140px;
    text-align: center;
  }
}
.prev-link,
.next-link {
  margin: 0 10px;
  cursor: pointer;
  img {
    width: 14px;
  }
}
.calendar-body {
  overflow: auto;
  padding-bottom: 10px;

  .calendar-note-bar {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .--note-item {
      padding: 2px 3px;
      .--note-color {
        width: 120px;
        height: 8px;
      }
    }
    .--task {
      .--note-label {
        color: #009606;
      }
      .--note-color {
        background-color: #009606;
      }
    }
    .--seed {
      .--note-label {
        color: $seeding-color;
      }
      .--note-color {
        background-color: $seeding-color;
      }
    }
    .--assessment {
      .--note-label {
        color: $assessment-color;
      }
      .--note-color {
        background-color: $assessment-color;
      }
    }
    .--maintenance {
      .--note-label {
        color: $maintenance-color;
      }
      .--note-color {
        background-color: $maintenance-color;
      }
    }
    .--inventory_seed {
      .--note-label {
        color: $maintenance-color;
      }
      .--note-color {
        background-color: $maintenance-color;
      }
    }
    .--harvest {
      .--note-label {
        color: $harvest-color;
      }
      .--note-color {
        background-color: $harvest-color;
      }
    }
    .--require-assessment {
      .--note-label {
        color: $assessment-color;
      }
      .--note-color {
        background-color: $assessment-color;
      }
    }
    .--refuel {
      .--note-label {
        color: $refuel-color;
      }
      .--note-color {
        background-color: $refuel-color;
      }
    }
  }
}
.calendar-table {
  width: 100%;
  min-width: 1000px;

  th {
    border: 0.5px solid #a1a1a1;
    text-align: center;
    font-size: 12.8px;
    padding: 5px 0px;
  }
  td {
    border: 0.5px solid #a1a1a1;
    min-height: 120px;
    height: 120px;
    width: 170px;
    min-width: 150px;
    vertical-align: top;
    position: relative;
    padding-bottom: 35px;

    .cell-wrap {
      .--entities {
        display: flex;
        flex-wrap: wrap;
      }
    }

    .--day {
      text-align: center;
      font-size: 14px;

      img.ant-image-img {
        max-width: 20px;
        position: absolute;
        right: 5px;
        top: 2px;
        cursor: pointer;
      }

      img.clock-image {
        right: 30px;
      }
    }
    .--entity {
      margin: 5px 3px;
      border-radius: 5px;
      padding: 3px 5px;
      transition: 0.3s;
      border-width: 3px;
      border-style: solid;
      max-width: 200px;
      width: 100%;

      .--header {
        text-align: right;
        margin-bottom: -5px;
        margin-top: -5px;
        span {
          font-size: 12.9px;
          font-weight: 600;
          background-color: black;
          color: white;
          padding: 2px 5px;
          border-radius: 20px;
        }
      }

      .--title {
        color: black;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        word-break: break-all;
      }

      .--btn-bar {
        margin-top: 5px;
        text-align: center;
      }
      .--view {
        text-decoration: underline;
        font-style: italic;
      }
    }
    .--task {
      border: 1px solid #009606;
      margin: 5px 3px;
      border-radius: 5px;
      padding: 3px 5px;
      transition: 0.3s;

      .--header span {
        background-color: #009606;
      }

      .--task-title {
        color: black;
        display: flex;
        align-items: center;
      }
      .--boat-img {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        border-radius: 30px;

        img {
          width: 15px;
          height: 15px;
          background-color: white;
          border-radius: 30px;
        }
      }
      .complete-btn {
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border: none;
        border-radius: 5px;
        background-color: #12b31a;
      }
      .edit-btn {
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border: none;
        border-radius: 5px;
        margin-left: 8px;
      }
      .delete-btn {
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border: none;
        border-radius: 5px;
        margin-left: 8px;
      }
    }
    .--task.--completed {
      background-color: #12b31a;
      border-color: #12b31a;
      color: white;
      .--task-title {
        color: white;
      }
    }
    .--task:hover {
      background-color: #009606;
      color: white;
      .--task-title {
        color: white;
      }
      .--header span {
        color: #009606;
        background-color: white;
      }
    }
    .--seed {
      border-color: $seeding-color;
      .--view {
        color: $seeding-color;
      }
      .--header span {
        background-color: $seeding-color;
      }
    }
    .--seed:hover {
      background-color: $seeding-color;
      color: white;
      .--title {
        color: white;
      }
      .--view {
        color: white;
      }
      .--header span {
        color: $seeding-color;
        background-color: white;
      }
    }
    .--assessment {
      border-color: $assessment-color;
      .--view {
        color: $assessment-color;
      }
      .--header span {
        background-color: $assessment-color;
      }
    }
    .--assessment:hover {
      background-color: $assessment-color;
      color: white;
      .--title {
        color: white;
      }
      .--view {
        color: white;
      }
      .--header span {
        color: $assessment-color;
        background-color: white;
      }
    }
    .--maintenance {
      border-color: $maintenance-color;
      .--view {
        color: $maintenance-color;
      }
      .--header span {
        background-color: $maintenance-color;
      }
    }
    .--maintenance:hover {
      background-color: $maintenance-color;
      color: white;
      .--title {
        color: white;
      }
      .--view {
        color: white;
      }
      .--header span {
        color: $maintenance-color;
        background-color: white;
      }
    }
    .--inventory_seed {
      border-color: $maintenance-color;
      .--view {
        color: $maintenance-color;
      }
      .--header span {
        background-color: $maintenance-color;
      }
    }
    .--inventory_seed:hover {
      background-color: $maintenance-color;
      color: white;
      .--title {
        color: white;
      }
      .--view {
        color: white;
      }
      .--header span {
        color: $maintenance-color;
        background-color: white;
      }
    }
    .--harvest {
      border-color: $harvest-color;
      .--view {
        color: $harvest-color;
      }
      .--header span {
        background-color: $harvest-color;
      }
    }
    .--harvest:hover {
      background-color: $harvest-color;
      color: white;
      .--title {
        color: white;
      }
      .--view {
        color: white;
      }
      .--header span {
        color: $harvest-color;
        background-color: white;
      }
    }
    .--require-assessment {
      border-color: $assessment-color;
      .--view {
        color: $assessment-color;
      }
      .--header span {
        background-color: $assessment-color;
      }
    }
    .--require-assessment:hover {
      background-color: $assessment-color;
      color: white;
      .--title {
        color: white;
      }
      .--view {
        color: white;
      }
      .--header span {
        color: $assessment-color;
        background-color: white;
      }
    }
    .--refuel {
      border-color: $refuel-color;
      .--view {
        color: $refuel-color;
      }
      .--header span {
        background-color: $refuel-color;
      }
    }
    .--refuel:hover {
      background-color: $refuel-color;
      color: white;
      .--title {
        color: white;
      }
      .--view {
        color: white;
      }
      .--header span {
        color: $refuel-color;
        background-color: white;
      }
    }

    .--buttons {
      position: absolute;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      width: 100%;
      padding: 5px;

      .paginator {
        display: flex;
        align-items: center;

        .page-num {
          margin: 0 5px;
          border: 1px solid #8bc34a;
          padding: 0 3px;
          border-radius: 3px;
        }
      }

      button {
        padding: 1px 2px;
        font-size: 14px;
        font-weight: 600;
        cursor: pointer;
      }
    }
    .--add-task {
      cursor: pointer;
      position: absolute;
      left: 2px;
      top: 2px;
      background-color: #008ed1;
      color: white;
      border: none;
      font-size: 12px;
      padding: 2px 5px;
      border-radius: 3px;
      font-weight: 500;
    }
    .--add-task:hover {
      background-color: #0075ad;
    }
  }
  td.--prev .--day,
  td.--next .--day {
    color: #828282;
  }
  td.--current .--day {
    color: black;
  }
  .--today {
    border-width: 2px;
    border-color: #000000;
    background-color: #ffff0021;
  }
}
.harvest-planner-page {
  .content {
    width: 100%;
    max-width: 100%;
    border-radius: 10px;
    overflow: auto;
    background-color: white;
    border: 5px solid #fff;
  }
  min-height: calc(100vh - 140px);

  .ant-table-thead .ant-table-cell {
    padding: 16px 6px;
  }
  .ant-table-tbody .ant-table-cell {
    padding: 6px;
  }
}
