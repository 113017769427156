.home-body {
  display: flex;
  justify-content: space-around;
  padding-top: 20px;
  padding-bottom: 20px;

  .task-button {
    border: 2px solid #0c8ced;
    border-bottom-width: 5px;
    border-radius: 7px;
    margin: 12px 3px;
    padding: 3px 5px;
    transition: 0.3s;
    cursor: pointer;
  }
  .task-button:hover {
    background-color: #0c8ced;
    color: white;
  }
  .task-button-completed {
    border: 2px solid #00aa0b;
    border-bottom-width: 5px;
  }
  .task-button-completed:hover {
    background-color: #00aa0b;
    color: white;
  }
  .--add-task {
    cursor: pointer;
    background-color: #008ed1;
    color: white;
    border: none;
    font-size: 14px;
    padding: 2px 8px;
    border-radius: 3px;
    font-weight: 500;
    margin-left: 5px;
  }
  .--add-task:hover {
    background-color: #0075ad;
  }
  .message-view {
    display: flex;
    align-items: center;
    margin: 8px 8px;
    padding: 7px;
    border: 1px solid #e5e5e5;

    .boat-image {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      border-radius: 30px;

      img {
        width: 15px;
        height: 15px;
        background-color: white;
        border-radius: 30px;
      }
    }
    .--message-content {
      flex: 1;
      margin-left: 20px;
      .--content {
        white-space: pre-wrap;
      }
      .--created-at {
        font-size: 12px;
        color: rgb(138, 141, 145);
        font-weight: 400;
        text-overflow: ellipsis;
        text-align: right;
      }
    }
  }
}
.square-300 {
  max-width: 300px;
  max-height: 300px;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 768px) {
  .home-body {
    display: block;
  }
}
