@import '../../styles/variables';

.view-modal {
  .info-card {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 5px;
    border: 0.5px solid #cccccc2e;
    flex: 1;
  }
  .info-label {
    font-size: 14px;
    font-weight: 400;
  }
  .info-value {
    font-size: 16px;
    font-weight: 500;
  }
}
.spats-tree {
  .node-view {
    overflow: auto;
    padding-bottom: 20px;

    > .--node {
      margin-left: auto;
      margin-right: auto;

      > .--line-bar {
        display: none;
      }
    }
  }
  .--node {
    width: fit-content;

    .--parent {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
    .--children {
      display: flex;
      justify-content: center;
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
    }
    .--box {
      max-width: 220px;
      min-width: 175px;
      text-align: left;

      > .--content {
        margin: 5px 5px;
        padding: 5px 5px;
        border-radius: 5px;
        border-width: 3px;
        border-style: solid;
        color: white;
        font-weight: 600;

        .--assessment {
          color: $assessment-color;
          font-style: italic;
        }
        .--maintenance {
          color: $maintenance-color;
          font-style: italic;
        }
        .--floats-manage {
          color: $inventory-seed-color;
          font-style: italic;
        }
      }
      > .--other {
        background-color: #6366f1;
        border-color: #6366f1;
      }
      > .--seeding {
        color: $seeding-color;
        border-color: $seeding-color;
      }
      > .--harvest {
        background-color: $harvest-color;
        border-color: $harvest-color;
      }
      > .--content.--focus {
        border-color: orange;
      }
    }
    .--line-bar {
      display: flex;
      flex: 1;
      position: relative;

      > .--edge-info {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        > span {
          background: #4338ca;
          color: white;
          border-radius: 30px;
          padding: 0px 5px;
          font-weight: 500;
        }
      }
    }
    .--left-line,
    .--right-line {
      flex: 1;
      height: 5px;
      background-color: #4338ca;
    }
    .--vert-line {
      width: 5.5px;
      height: 55px;
      background-color: #4338ca;
    }
    .--children > .--node:first-child > .--line-bar > .--left-line {
      visibility: hidden;
    }
    .--children > .--node:last-child > .--line-bar > .--right-line {
      visibility: hidden;
    }
  }
}
.zoom-icon-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
