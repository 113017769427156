.farm-line-desktop-template {
  .line-name {
    color: #17090e !important;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: -0.01em;
  }
  .prev-link {
    margin: 0 10px;
    cursor: pointer;
    img {
      width: 14px;
    }
  }
  .next-link {
    margin: 0 10px;
    cursor: pointer;
    img {
      width: 14px;
    }
  }
  .--spat-count {
    font-size: 12.5px;
    border: 2px solid #009606;
    text-align: center;
    border-radius: 30px;
    padding: 3px 5px;
    max-width: 150px;
    font-weight: 600;
    margin: 5px 0px;
    color: #004b03;
    cursor: pointer;
    transition: 0.3s;
  }
  .--spat-count:hover {
    background-color: #009606;
    color: white;
  }
}
.verifier-name {
  font-size: 12px;
  color: chocolate;
}
.timeline-button {
  color: #4f46e5;
  border-radius: 5px;
  border: 2px solid #4f46e5;
  background: white;
  font-size: 16px;
  padding: 5px 10px;
  font-weight: 600;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background-color: #4f46e5;
    color: white;

    .ant-spin-dot-item {
      background-color: white;
    }
  }
  .ant-spin-dot-item {
    background-color: #4f46e5;
  }
}
.timeline-button.--checked {
  background-color: #4f46e5;
  color: white;
  &:hover {
    background-color: white;
    color: #4f46e5;
  }
}
