.pictures-wall {
  &.ant-upload-picture-card-wrapper {
    width: 100%;
  }

  .ant-upload.ant-upload-select-picture-card {
    width: 104px;
    height: 104px;
    margin-left: 0;
    margin-right: 8px;
    background-color: #fafafa;
    border: 1px dashed #d9d9d9;
  }
}
