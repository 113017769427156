@import '../../styles/variables';

.card.user-card {
  max-width:508px;
  height: max-content;
  margin: 26px 12px 62px 12px;

  border: none;
}

.user-smallCard {
  width: calc(100% / 2 - 7px);

  padding: 18px 12px 16px 16px;
  margin-bottom: 13px;
  margin-right: 14px;

  background-color: $white;
  border-radius: 12px;

  &:nth-child(2n) {
    margin-right: 0;
  }
}

.flex-wrap {
  flex-wrap: wrap;
}

.user-collapse {
  div.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 12px 12px 12px 44px;
    margin-bottom: 8px;

    background-color: $white;
    border: 1px solid $gray-8;
    border-radius: 6px !important;

    color: $black-1;
  }

  div.ant-collapse-content, div.ant-collapse > .ant-collapse-item, div.ant-collapse {
    border: none;
  }

  div.ant-collapse {
    background-color: $white;
  }

  .ant-collapse-header .ant-checkbox + span {
    display: none;
  }

  div.ant-collapse-content-box {
    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
      margin-left: 0;
    }

    .ant-checkbox-wrapper {
      margin-bottom: 8px;
      padding: 10px 12px 10px 4px;

      border: 1px solid $gray-8;
      border-radius: 6px;
    }

    .paragrapgh {
      font-size: 14px;
    }
  }

  div.ant-collapse-content > .ant-collapse-content-box {
    padding: 0 0 0 22px;
  }

  .ant-collapse-item-disabled {
    .ant-collapse-content {
      display: none;
    }
  }

  div.ant-collapse > .ant-collapse-item-disabled > .ant-collapse-header {
    background-color: $gray-11;
    border: none;
  }

  .accordion__icon {
    position: absolute;
    top: 50%;
    left: 34px;

    font-size: 0;

    transition: transform 0.5s;

    &--rotate-0 {
      transform: translate(-20px,-50% ) rotate(0deg);
    }
    &--rotate-180 {
      transform: translate(-20px,-50%) rotate(-180deg);
    }
  }

}

@media screen and (max-width: 768px) {
  .card.user-card {
    margin: 20px 12px 62px;

    .card-wrapper {
      padding: 12px 12px 8px !important;
    }
  }

  .user-button {
   flex-direction: column-reverse;

    .button:last-child {
      margin: 0 0 8px;
    }
  }
}

@media screen and (max-width: 620px) {
  .user-smallCard {
    width: 100%;

    margin: 0 0 13px 0 !important;
  }
}