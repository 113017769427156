.spat-storage-modal {
  &__condition {
    padding-bottom: 17px;

    &-item {
      max-width: 149px;
    }
  }
}

@media screen and (max-width: 568px) {
  .spat-storage-modal {
    &__condition {
      flex-direction: column;

      padding-top: 0;

      &-item {
        padding-top: 16px;
        max-width: 100%;
      }
    }
  }
}


.bucket-grid {
  padding: 16px;
}

.bucket {
  border: 1px solid #1890ff;
  height: 100px;
  display: flex;
  flex-wrap: wrap;
}

.division {
  flex: 1 1 calc(100% / 2); /* Divides each bucket into 4 */
  border: 1px dashed #73d13d;
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
