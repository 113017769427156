@import '../../styles/variables';

.table {
  padding: 16px;

  background: $white;
  border-radius: 12px;

  &__owner {
    padding-left: 6px;

    &:first-child {
      padding-left: 0px;
    }
  }

  //ant table

  & .ant-table-cell.ant-table-row-expand-icon-cell {
    padding: 0;
  }

  & .ant-table-thead > tr > th,
  & .ant-table-tbody > tr > td {
    border-bottom: 0;
  }

  &__menu-item:focus {
    outline: none;
  }

  //thead

  .ant-table-thead > tr > th {
    padding: 12px 16px;

    background: $gray-7;

    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;

    line-height: 16px;
    color: $gray-3;
  }

  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  .ant-table-container table > thead > tr:last-child th:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  .ant-table-cell {
    .tables-card__coefficient {
      position: static;
    }
  }

  //tbody

  & .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table {
    margin: 0;
  }

  & .ant-table-tbody > tr > td {
    padding: 14px 16px 13px 16px;

    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;

    line-height: 20px;
    color: $black-1;

    cursor: pointer;
  }

  & .ant-table-tbody > tr > td:first-child {
    & div[data-line='line'] {
      padding-left: 10px;
    }
  }

  & .ant-table-tbody > tr.ant-table-row:hover > td {
    background: none;
  }

  & .ant-table-tbody .ant-table-cell:last-child {
    text-align: right;
  }
  // all

  & tr.ant-table-expanded-row > td,
  tr.ant-table-expanded-row:hover > td {
    padding: 0;
  }

  &--is__child {
    padding: 0;

    & .ant-table-thead > tr > th {
      padding-top: 8px;
      padding-bottom: 8px;
    }

    & .ant-table-container table > thead > tr:first-child th:first-child {
      padding-left: 16px;
    }
  }
}

.table--units {
  & .ant-table-tbody tr.ant-table-row-level-0:nth-child(2n) {
    background: #fafafa;
  }
}

.btn__modal {
  color: $brand-2;

  cursor: pointer;
}

.table .table--is__child {
  & .ant-table-tbody > tr > td {
    padding: 6px 16px 5px 16px;
  }
}

.table--small {
  padding: 5px 0px !important;

  .ant-table-thead .ant-table-cell {
    padding: 16px 6px;
  }
  .ant-table-tbody .ant-table-cell {
    padding: 6px;
    line-height: 14px;
  }

  .input {
    padding: 6px 9px;
    height: 37px;
  }
}

.direction-form {
  .btn-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    width: 100%;
    border: 1px solid #efefef;
    border-radius: 4px;
    font-family: 'Inter', sans-serif;
    font-size: 16px;

    .dir-btn {
      padding: 5px;
    }
    .dir-btn img {
      cursor: pointer;
      height: 50px;
    }
    .dir-btn.selected {
      border-color: green;
      border-width: 2px;
      border-radius: 5px;
      border-style: solid;
    }
  }
}
