@import '../../../styles/variables';

.title {
  margin: 0;

  color: $black-1;

  &--1 {
    font-size: 80px;
    line-height: 1.4;
  }

  &--2 {
    font-size: 64px;
    line-height: 1.41;
  }

  &--3 {
    font-size: 48px;
    line-height: 1.37;
  }

  &--4 {
    font-size: 32px;
    line-height: 1.37;
  }

  &--5 {
    font-size: 24px;
    line-height: 1.5;
    letter-spacing: 0.02em;
  }

  &--6 {
    font-size: 20px;
    line-height: 1.4;
    letter-spacing: 0.02em;
  }

}