.harvest-type-list {
  .ml-0 {
    margin-left: 0px !important;
  }
  .--other-option {
    display: flex;
    margin: 15px 0px;
    justify-content: space-between;
    align-items: center;
  }
  .--other-delete-btn {
    border: none;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    border-radius: 5px;
    cursor: pointer;
  }
}
.task-settings {
  .task-label {
    margin-top: 10px;
    padding-bottom: 5px;
    padding-top: 10px;
    background-color: #fafafa;
    padding-left: 10px;
  }
}
.xero-item {
  border: 1px solid #b3b3b3;
  margin: 10px 0;
  padding: 10px;
  background-color: #fff;

  .--label {
    font-size: 14px;
    color: #7b7b7b;
  }
  .--value {
    font-size: 18px;
    color: #181b1f;
    font-weight: 700;
  }
}
