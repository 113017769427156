@import '../../../styles/variables';

div.ant-modal-body {
  padding: 24px;

  background: $white;
  box-shadow: 0px 2px 2px rgba(50, 50, 71, 0.06),
    0px 2px 4px rgba(50, 50, 71, 0.06);
  border-radius: 6px;
}

.ant-modal-close .ant-modal-close-x {
  width: 60px;

  line-height: 78px;
}

.ant-modal-close-x path {
  stroke: $black-1;
}

div.ant-modal-content {
  border-radius: 6px;
}

@media screen and (max-width: 768px) {
  .modal-button {
    flex-direction: column-reverse;

    .button:last-child {
      margin: 0 0 8px;
    }
  }

  div.ant-modal-body {
    padding: 16px 12px 12px;
  }

  .ant-modal-close .ant-modal-close-x {
    width: 40px;

    line-height: 60px;
  }
}

.invalid-form {
  input {
    border-color: #dc3545 !important;
  }
  .ant-select-selector {
    border-color: #dc3545 !important;
  }
  .invalid-feedback {
    margin-top: 0.1rem;
    font-size: 80%;
    color: #dc3545;

    a {
      color: #dc3545;
      text-decoration: underline;
    }
  }
}
