.line-spats-page {
  padding-bottom: 7px;
  .filter-options {
    display: flex;
    justify-content: space-between;

    .ant-select {
      min-width: 200px;
    }
  }

  @media screen and (max-width: 768px) {
    .filter-options {
      flex-direction: column;

      .ant-select {
        min-width: 150px;
      }
    }
    .filter-options > .d-flex {
      margin: 10px 0px;
      justify-content: space-between;
    }
    .paginator {
      text-align: center;
    }
    .action-btn {
      margin-top: 10px;
      text-align: center;
    }
  }
}
