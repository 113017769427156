@import 'main';
@import 'farms';
@import 'radio';
@import 'toggle';
@import 'checkbox';
@import 'variables';
@import 'spaces';
@import 'typography';
@import 'mobile';

::-webkit-scrollbar {
  width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #bbb;
  border-color: #bbb;
  border-radius: 10px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #aaa;
  border-color: #aaa;
}
