.farms {
  padding: 0 15px;

  &__header {
    padding-top: 28px;
    padding-bottom: 28px;
    padding-left: 6px;
  }

  &__content-cards {
    padding-left: 24px;
  }
}

@media screen and (max-width: 1100px) {
  .farms {
    padding: 0;

    &__header {
      padding-top: 20px;
      padding-bottom: 16px;
      padding-left: 0;
    }

    &__content {
      flex-direction: column;
    }

    &__content-cards {
      padding-top: 20px;
      padding-left: 0;
    }
  }
}

@media screen and (max-width: 768px) {
  .farms {
    &__content-cards {
      padding-top: 4px;

    }
  }
}