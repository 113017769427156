$spaces: (
  0,
  3,
  4,
  6,
  7,
  8,
  10,
  12,
  14,
  15,
  16,
  17,
  18,
  20,
  21,
  22,
  23,
  25,
  24,
  27,
  26,
  28,
  30,
  32,
  34,
  36,
  39,
  40,
  45,
  48,
  50,
  52,
  62,
  70,
  100,
  200,
  500
);

@each $value in $spaces {
  .mt {
    &-#{$value} {
      margin-top: #{$value}px;
    }
  }
  .mb {
    &-#{$value} {
      margin-bottom: #{$value}px;
    }
  }
  .ml {
    &-#{$value} {
      margin-left: #{$value}px;
    }
  }
  .mr {
    &-#{$value} {
      margin-right: #{$value}px;
    }
  }
  .pt {
    &-#{$value} {
      padding-top: #{$value}px;
    }
  }
  .pb {
    &-#{$value} {
      padding-bottom: #{$value}px;
    }
  }
  .pl {
    &-#{$value} {
      padding-left: #{$value}px;
    }
  }
  .pr {
    &-#{$value} {
      padding-right: #{$value}px;
    }
  }
  .w {
    &-#{$value} {
      width: #{$value + '%'};
    }
  }
  .mxw {
    &-#{$value} {
      max-width: #{$value}px;
    }
  }
  .mnw {
    &-#{$value} {
      min-width: #{$value}px;
    }
  }
}
