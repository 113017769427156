.lines-page {
  .farm-name {
    color: #303030;
    font-size: 14px;
    line-height: 20px;
  }
  .farm-number {
    color: #5a607f;
    font-size: 12px;
    line-height: 16px;
  }
  .filter-option {
    min-width: 220px;
  }
}
