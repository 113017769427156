@import '../../styles/variables';

.add-line {
  padding-top: 37px;
}

.budget {
  &__wrapper {
    max-width: 353px;
    width: 100%;
  }

  &__price-wrapper {
    max-width: 192px;
  }

  &__close-icon {
    position: absolute;
    right: -30px;
    bottom: 15px;

    font-size: 0;

    cursor: pointer;
    transition: all 0.3s;

    svg path {
      stroke: $gray-5;
    }

    &:hover {
      svg path {
        stroke: $red-1;
      }
    }

    &:focus {
      outline: none;
    }
  }

  &__seeding,
  &__maintenance {
    .ant-checkbox-wrapper .paragrapgh,
    .ant-radio-group .paragrapgh,
    .ant-radio-group .dropdown__label {
      display: block !important;
    }
  }

  &__seeding:not(:first-of-type),
  &__maintenance:not(:first-of-type) {
    .paragrapgh,
    .dropdown__label {
      display: none;
    }
  }
}

@media screen and (max-width: 768px) {
  .add-line {
    flex-direction: column;

    padding-top: 6px;
  }

  .line-button {
    flex-direction: column-reverse;

    .button {
      margin: 0 0 30px;

      &:last-child {
        margin: 0 0 8px;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .budget {
    &__close-icon {
      right: 10px;
    }
  }
}

@media screen and (max-width: 350px) {
  .budget {
    &__close-icon {
      right: 0;
    }
  }
}

.time-line-container {
  margin-top: 12px;
  margin-bottom: 12px;
  position: relative;
  padding: 20px 10px;
  border: 1px solid #4f46e5;
  border-radius: 5px;
  background-color: white;
  min-width: 600px;

  .--line-bar {
    background-color: #6366f1;
    height: 5px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .--time-item {
      width: 25px;
      height: 25px;
      border-radius: 25px;
      background-color: #4338ca;
      border: 5px solid #6366f1;
    }
  }
  .--day-bar {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;

    .--day {
      font-size: 15px;
      font-weight: 700;
      color: #0c4a6e;
      width: 250px;
      text-align: center;
    }
  }
  .--box-bar {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;

    .--box {
      width: 250px;
      text-align: center;
    }
  }

  .--data-box {
    position: relative;
    display: flex;
    flex: 1;
    border-top: 1px solid #6366f1;

    &:first-child {
      border-top: none;
    }

    .--day {
      font-size: 16px;
      font-weight: 700;
      color: #0c4a6e;
      width: 250px;
      min-width: 250px;
      border-right: 10px solid #6366f1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .--circle {
      position: absolute;
      left: 230px;
      top: calc(50% - 15px);
      width: 30px;
      height: 30px;
      border-radius: 30px;
      background-color: #4338ca;
      border: 5px solid #6366f1;
    }
    .--box {
      display: flex;
      flex-wrap: wrap;
      margin: 20px 20px;

      .--cell {
        width: 260px;
        padding: 5px 10px;
        margin: 10px 10px;
      }
    }
  }

  .--cell {
    color: white;
    cursor: pointer;
    font-size: 15px;
    font-weight: 400;
    margin: 10px 0;
    padding: 5px 0;
    border-radius: 5px;
    transition: 0.3s;
    border-width: 2px;
    border-style: solid;
    box-sizing: border-box;
  }
  .--cell.--seeding {
    background-color: $seeding-color;
    border-color: $seeding-color;
    &:hover {
      color: $seeding-color;
      background-color: white;
    }
  }
  .--cell.--assessment {
    background-color: $assessment-color;
    border-color: $assessment-color;
    &:hover {
      color: $assessment-color;
      background-color: white;
    }
  }
  .--cell.--maintenance {
    background-color: $maintenance-color;
    border-color: $maintenance-color;
    &:hover {
      color: $maintenance-color;
      background-color: white;
    }
  }
  .--cell.--inventory_seed {
    background-color: $inventory_seed-color;
    border-color: $inventory_seed-color;
    &:hover {
      color: $inventory_seed-color;
      background-color: white;
    }
  }
  .--cell.--harvest {
    background-color: $harvest-color;
    border-color: $harvest-color;
    &:hover {
      color: $harvest-color;
      background-color: white;
    }
  }
  .--cell.--seed_manage {
    background-color: $seed_manage-color;
    border-color: $seed_manage-color;
    &:hover {
      color: $seed_manage-color;
      background-color: white;
    }
  }
  .--cell.--tag_log {
    background-color: $tag_log-color;
    border-color: $tag_log-color;
    &:hover {
      color: $tag_log-color;
      background-color: white;
    }
  }
  .--cell.--harvest_planned {
    background-color: $harvest-color;
    border-color: $harvest-color;
    &:hover {
      color: $harvest-color;
      background-color: white;
    }
  }
}
.line-tag-btn {
  border: 2px solid #1a91ff;
  text-align: center;
  border-radius: 30px;
  padding: 1.5px 5px 3px 5px;
  max-width: 170px;
  min-width: 80px;
  margin: 5px 0px;
  color: #0077e6;
  cursor: pointer;
  transition: 0.3s;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.line-tag-btn:hover {
  background-color: #1a91ff;
  color: white;
}
.harvest-predict-btn {
  font-size: 14px;
  border: 2px solid #fb923c;
  text-align: center;
  border-radius: 3px;
  padding: 3px 8px;
  min-width: 80px;
  font-weight: 600;
  color: #f97316;
  cursor: pointer;
  transition: 0.3s;
  background-color: white;
}
.harvest-predict-btn:hover {
  background-color: #fb923c;
  color: white;
}
