.white-card {
  padding: 15px;
  margin: 20px 0px;
}
.cursor-text {
  cursor: text !important;
}
.comment {
  font-size: 12px;
  margin: 0 3px;
}
.comment-username {
  font-weight: bold;
  font-style: italic;
}
.comment-date {
  color: #111111;
  font-style: italic;
}
.delete-item {
  border: none;
  background: none;
  cursor: pointer;
}
.white-card {
  width: 100%;
}
.overview__left-content {
  overflow-y: auto;
  max-height: 800px;
}
.unread {
  border: 1px red solid;
}
.message-icon {
  width: 47.5px;
}
.notification-count-icon {
  background-color: #ff0000d7;
  color: white !important;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  font-weight: bold;
  padding: 0px 5px;
  font-size: 12px;
  top: 15%;
  right: 5px;
}
.message-content {
  background: #efefef6e;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #efefef;
}
