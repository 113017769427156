.suggest-page {
  padding: 8px 18px;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;

  .suggest-sentence {
    font-weight: 500;
    margin-bottom: 7px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.01em;
    font-family: 'Inter', sans-serif;
    color: #303030;
  }
  .suggest-info {
    max-width: 100%;
    border: 1px solid #91d5ff !important;
    border-radius: 1px;
  }
  .suggest-warning {
    max-width: 100%;
    border: 1px solid #ffe58f !important;
    border-radius: 1px;
  }
  .italic {
    font-style: italic;
  }
}
.suggest-page.order-suggest {
  max-width: 1500px;

  .table--isFarm {
    padding: 2px 0px;
  }
}
