.report-page {
  .report--header {
    padding-top: 28px;
    padding-bottom: 28px;
    padding-left: 6px;
  }
  .report--body {
    display: flex;
    justify-content: space-between;
    .farm-line {
      flex: 1;
    }
    .date-range {
      margin-bottom: 20px;
      margin-left: 5px;
      margin-right: 5px;
      display: flex;
      justify-content: space-between;
    }
    .render-content {
      flex: 2.5;
      padding: 5px;
    }
    .render-header {
      display: flex;
    }
    .render-body {
      padding: 2px;
    }
    .report-options {
      margin-bottom: 20px;
      margin-left: 5px;
      margin-right: 5px;
      display: flex;
      justify-content: space-between;
    }
  }
  .row-item {
    border: 1px solid #54545421;
    display: flex;
    margin: 3px 5px;
    padding: 8px 5px;
    align-items: center;
    transition: 0.3s;
    .level-farm {
      flex: 1;
    }
    .level-line {
      flex: 2;
    }
    .level-assessment {
      flex: 3;
    }
    .exp-col {
      flex: 1;
      cursor: pointer;
      transition: 0.3s;
    }
    .collapsed img {
      width: 10px;
    }
    .expanded img {
      height: 10px;
    }
    .label {
      flex: 8;
      span {
        cursor: pointer;
      }
    }
    .checker {
      flex: 1;
      text-align: right;
      display: flex;
      justify-content: flex-end;
    }
  }
  .preview-pdf {
    width: 100%;
    min-height: 900px;
    margin-top: 15px;
    margin-bottom: 15px;
    border-radius: 3px;
  }
  padding-bottom: 50px;
}
