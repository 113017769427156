@import '../../../styles/variables';

.input {
  padding: 12px 16px;

  height: 48px;
  width: 100%;

  border: 1px solid $gray-8;
  border-radius: 4px;

  font-family: 'Inter', sans-serif;
  font-size: 16px;
  letter-spacing: -0.01em;
  color: $black-1;

  appearance: none;

  &::placeholder {
    color: $black-2;
  }

  &--success {
    border: 1px solid $green-1;
  }

  &--error {
    border: 1px solid $red-1;
  }

  &:focus {
    border: 1px solid $brand-2;

    outline: none;
  }

  &--readonly {
    cursor: default;
  }

  &--readonly:focus {
    border: 1px solid $gray-8;
  }

  &--disabled {
    background: $gray-11;

    color: $black-2;

    transition: all 0.3s;
    cursor: not-allowed;
  }

  &__icon {
    position: absolute;
    top: 14px;
    right: 16px;

    transition: all 0.3s;
    cursor: pointer;
  }

  &--marker {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &__marker {
    align-self: center;

    padding: 11px 16px;

    background-color: $gray-11;
    border: 1px solid $gray-8;
    border-radius: 0 4px 4px 0;
    border-left: 0;

    color: $gray-3;
    white-space: nowrap;
  }

  &--textarea {
    min-height: 100px;

    resize: none;
  }
}

div.ant-picker-footer {
  display: block !important;
}
