.farm-visual-lines-page {
  .groups-bar {
    margin-bottom: 10px;
    padding-bottom: 20px;
  }
  .visual-lines-group {
    margin: 20px;
    padding: 15px 20px;
    background-color: #fff;
    border-radius: 10px;
    border-width: 0.5px;
    border-style: solid;
    border-color: #eee;
  }
  .visual-line-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 25px;
    cursor: move;
    padding-right: 5px;
  }
  .visual-line-item.over {
    border: 0.5px dashed black;
  }
  .visual-line-label {
    flex: 1;
    text-align: center;
    font-size: 18px;
  }
  .visual-line-line {
    flex: 10;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .visual-line-outer {
    border-width: 0.7px;
    border-style: solid;
    border-radius: 10px;
    border-color: #847d7d;
    padding: 4px;
    transition: 0.3s;
    cursor: pointer;
  }
  .visual-line-outer:hover {
    background-color: #3e8e41;
    .visual-line-inner {
      background-color: whitesmoke;
    }
  }
  .visual-line-inner {
    border-radius: 10px;
    border: none;
    flex: 1;
    min-height: 11px;
  }
  .selected-item {
    border: 1px solid black;
  }
  .--empty {
    background-color: #d30202;
  }
  .--nursery {
    background-color: #16131b;
  }
  .--ready_harvest {
    background-color: #409900;
  }
  .--require_assessment {
    background-color: #aeef75;
  }
  .--catch_spat {
    background-color: #c5a800;
  }
  .--normal {
    background-color: #16131b;
  }
  .groups-bar {
    overflow: auto;
    display: flex;
  }
  .new-group {
    margin: 20px;
    padding: 15px 20px;
    background-color: #fff;
    border-radius: 10px;
    border-width: 0.5px;
    border-style: solid;
    border-color: #eee;
    min-width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      border: 1px dashed black;
      padding: 20px;
      margin: 10px;
    }
  }
  .text-right {
    text-align: right;
  }
  .text-center {
    text-align: center;
  }
  .text-left {
    text-align: left;
  }
  .legend-label {
    text-align: center;
    font-size: 12px;
    margin-left: 10px;
  }
  .legend-inner {
    padding: 4px;
    width: 100px;
    border-radius: 10px;
    border: none;
    min-height: 11px;
  }
  .float-right {
    float: right;
  }
  .legend-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
}
.pos-absolute {
  position: absolute !important;
}
.btn-link {
  font-weight: 400;
  color: #007bff;
  background-color: transparent;
}
.btn-link:hover {
  text-decoration: underline;
}
.visual-farms-page {
  .farms-content {
    margin: 3px;
    text-align: center;
  }
  .farm-visual-item {
    background-color: #fff;
    border-radius: 10px;
    border-width: 0.5px;
    border-style: solid;
    border-color: #eee;
    padding: 5px 15px;
    margin: 20px 0px;
    position: relative;
  }
  .farm-item-name {
    font-size: 18px;
    text-align: center;
    padding: 10px 20px 5px 20px;
  }
  .farm-item-body {
    margin: 10px 0px 39px 0px;
    text-align: center;
  }
  .line-column {
    position: relative;
    margin: 15px 0px;
  }
  .new-column {
    position: relative;
    margin: 15px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed rgb(199, 199, 199);
    min-height: 50px;
  }
  .column-name {
    text-align: center;
    font-weight: 700;
    font-size: 18px;
  }
  .line-item {
    padding: 10px 10px;
    width: 100%;
    margin: 10px 0px;
    position: relative;
  }
  .line-label {
    cursor: move;
    font-size: 14px;
    position: absolute;
    background-color: white;
    border-radius: 20px;
    border: 0.7px solid #847d7d;
    text-align: center;
    min-width: 20px;
    height: 20.5px;
    padding: 0px 5px;
    margin-top: -5.5px;
    margin-left: -2px;
  }
  .line-outer {
    width: 100%;
    border-width: 0.3px;
    border-style: solid;
    border-radius: 10px;
    border-color: #847d7d;
    padding: 1px;
    transition: 0.3s;
    cursor: pointer;
    margin-left: 15px;
  }
  .line-outer:hover {
    background-color: #3e8e41;
    .line-inner {
      background-color: whitesmoke;
    }
  }
  .line-floats {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 30px;
    min-height: 3px;
    position: relative;

    .floats-item {
      display: flex;
      position: absolute;

      .floats-dot {
        border-width: 1px;
        border-color: white;
        border-style: solid;
        height: 10px;
        width: 10px;
        margin: -10px;
        border-radius: 50%;
        z-index: 1;
        transition: 0.3s;
        cursor: col-resize;

        &:hover {
          background-color: gray !important;
          border-color: black;
        }
      }
      .floats-leg {
        width: 0.5px;
        background-color: #16131b1f;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: -3px;
        margin-top: -3px;
      }
    }
  }
  .line-inner {
    border-radius: 10px;
    border: none;
    min-height: 5px;
  }
  .farm-item-action {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
  .text-right {
    text-align: right;
  }
  .text-center {
    text-align: center;
  }
  .text-left {
    text-align: left;
  }
  .legend-label {
    text-align: center;
    font-size: 12px;
    margin-left: 10px;
  }
  .legend-inner {
    padding: 4px;
    width: 100px;
    border-radius: 10px;
    border: none;
    min-height: 11px;
  }
  .float-right {
    float: right;
  }
  .legend-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .--empty {
    background-color: #d30202;
  }
  .--nursery {
    background-color: #16131b;
  }
  .--ready_harvest {
    background-color: #409900;
  }
  .--require_assessment {
    background-color: #aeef75;
  }
  .--catch_spat {
    background-color: #c5a800;
  }
  .--normal {
    background-color: #16131b;
  }
  .farms-footer {
    display: flex;
    justify-content: flex-end;
  }
  .paginator {
    margin: 30px 20px;
    width: 100%;
    text-align: center;
  }
  .dashed-area {
    border: 0.3px dashed rgb(199, 199, 199);
  }
  .flash-dot {
    border-color: red !important;
    animation: flash 1s ease infinite alternate;
  }
  @media screen and (max-width: 768px) {
    .farm-visual-item {
      width: 100%;
    }
  }
}
.farms-map-page {
  padding-bottom: 7px;
  .farm-marker {
    margin-top: -10px;
    .--header {
      padding: 2px;
      .--name {
        font-size: 15px;
        font-weight: 400;
        margin-right: 5px;
      }
      .--farm_number {
        font-size: 12px;
        font-weight: 600;
      }
    }
    .--footer {
      margin-top: 10px;
      text-align: center;
    }
  }
  .map-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 20px;
    padding-right: 20px;
    .filter-option {
      min-width: 260px;
    }
  }
  .map-content {
    height: auto;
    min-height: 600px;
    display: flex;
    justify-content: space-between;
    .--map {
      position: relative;
      flex: 1;
    }
    .--farms {
      width: 300px;
      padding: 0 20px;
      .--farm-button {
        font-size: 0.875rem;
        background-color: rgba(255, 255, 255, 0.85);
        background-clip: padding-box;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 0.25rem;
        box-shadow: 0 0.25rem 0.75rem rgb(0 0 0 / 10%);
        backdrop-filter: blur(10px);
        padding: 7px 12px;
        margin-bottom: 20px;
        cursor: pointer;
        .--lines-count {
          padding-top: 5px;
          .--total-count {
            font-weight: 600;
            color: #8d02ff;
          }
          .--empty-count {
            font-weight: 600;
            color: #d30202;
          }
          .--ready_harvest-count {
            font-weight: 600;
            color: #409900;
          }
          .--require_assessment-count {
            font-weight: 600;
            color: #aeef75;
          }
          .--catch_spat-count {
            font-weight: 600;
            color: #c5a800;
          }
          .--growing-count {
            font-weight: 600;
            color: #16131b;
          }
        }
      }
      .--location {
        margin-top: 3px;
        font-size: 0.7rem;
      }
      .--stages {
        margin-top: 10px;
        padding: 10px;
        background-color: rgba(255, 255, 255, 0.85);
        background-clip: padding-box;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 0.25rem;
        box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
      }
    }
  }
  .--boat-img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 30px;

    img {
      width: 15px;
      height: 15px;
      background-color: white;
      border-radius: 30px;
    }
  }
  @media screen and (max-width: 768px) {
    .map-header {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      flex-direction: column;
      padding-right: 0px;
      .filter-option {
        width: 100%;
        margin-top: 20px;
      }
    }
    .map-content {
      flex-direction: column;
      .--map {
        min-height: 350px;
      }
      .--farms {
        margin-top: 30px;
        width: 100%;
        padding: 10px 0;
      }
    }
  }
  .gm-style .gm-style-iw-c {
    transform: translate(-50%, -100%);
  }
}

@keyframes flash {
  50% {
    opacity: 0.1;
  }
}
.map-type-div {
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 1;
  width: 150px;

  .ant-select-selector {
    padding: 6px 8px !important;
    height: 35px !important;
  }
}


.table--isSpats.table {
  .ant-table-tbody .ant-table-cell:last-child {
    text-align: left;
  }
  @media screen and (max-width: 768px) {
    .table--isLinesSpats {
      .ant-spin-container {
        max-width: 80vw;
        overflow-y: scroll;
      }
    }
  }
}
