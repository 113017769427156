.map {
  min-width: 300px;
  min-height: 400px;
  width: 100%;
  height: 100%;

  position: relative;

  &__pin-label {
    font-family: Inter, sans-serif;
    font-size: 12px;
    line-height: 16px;

    color: #303030;
  }
}

.gm-style-cc,
.gm-svpc,
.gm-style-mtc,
.gm-style div div a div,
button.gm-ui-hover-effect,
.gm-style .gm-style-iw-t::after,
.gm-control-active.gm-fullscreen-control {
  display: none !important;
}

.gm-style .gm-style-iw-d {
  padding: 8px 12px;

  overflow: auto !important;
}

.gm-style .gm-style-iw-c {
  padding: 0;

  transform: translate(-50%, -70%);
}
.gm-style-iw.gm-style-iw-c {
  opacity: 0.7;
}
