@import '../../../styles/variables';


.dropdown {
  cursor: pointer;
}

.ant-dropdown-menu.ant-dropdown-menu-light.ant-dropdown-menu-root.ant-dropdown-menu-vertical {
  width: 189px;

  padding: 16px;

  background: $white;
  border-radius: 6px;

  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;

  line-height: 20px;
  color: $black-1;

  filter: drop-shadow(0px 2px 20px rgba(0, 0, 0, 0.08));
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child {
  padding: 0;

  border-bottom: 12px solid $white;

  &:last-child {
    border-top: 0;
    border-bottom: 0;
  }
}

