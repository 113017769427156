.qr-scan-page {
  .status-box {
    margin-top: 35px;
    margin-bottom: 35px;

    .--done-txt {
      text-transform: capitalize;
      font-size: 19px;
      color: green;
      font-weight: bold;
      margin-right: 15px;
    }
    .--blank-btn {
      display: flex;
      padding: 15px 25px;
      border-color: #bae6fd;
      border-width: 1px;
      border-radius: 5px;
      align-items: center;
      background-color: #e0f2fe;
    }
    .--blank-btn:active {
      background-color: #e0f2feaa;
    }
    .--blank-txt {
      text-transform: capitalize;
      font-size: 19px;
      color: #658ca4;
      font-weight: bold;
      margin-right: 15px;
    }
    .--record-txt {
      color: #ef9f0e;
      font-size: 14px;
      padding-left: 15px;
      font-weight: bold;
    }
    .--remove-btn {
      background-color: #fee2e2;
      padding-top: 5px;
      padding-bottom: 5px;
      border-radius: 5px;
      border-color: #f87171;
      border-width: 1px;
      margin-left: auto;
      margin-top: 5px;
      margin-bottom: 10px;
      width: 120px;
      text-align: center;
    }
    .--remove-btn:active {
      background-color: #fee2e2aa;
    }
    .--disabled-btn {
      background-color: #e5e5e5;
    }
  }
  .bag-box {
    margin-top: 15px;
    background-color: #fef3c7;
    margin-left: 9px;
    margin-right: 9px;
    padding: 12px;

    .--label {
      color: #22c55e;
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 6px;
    }
    .--data-row {
      display: flex;
      justify-content: space-between;
      border-bottom-color: #71717a;
      border-bottom-width: 1px;
      border-bottom-style: solid;
      padding-left: 6px;
      padding-right: 6px;
      margin-bottom: 6px;
    }
    .--data-txt {
      font-size: 15px;
      color: #166534;
    }
    .--user-name {
      color: #ef9f0e;
      text-align: right;
      margin-top: 6px;
      margin-left: 6px;
      margin-right: 6px;
      font-size: 16px;
    }
  }
  .--button {
    background-color: green;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    width: 260px;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .--back-btn {
    background-color: #64748b;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    width: 260px;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .--back-btn:active {
    background-color: #64748baa;
  }
  .--btn-txt {
    color: white;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
  .--notify-txt {
    text-align: center;
    color: #4d7c0f;
    font-size: 17px;
    font-weight: 700;
  }
}
.qr-code-div {
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
