.queued-page-header {
  padding: 27px 15px 29px 21px;
}
.queued-data-page {
  .queue-items {
    display: flex;
    flex-wrap: wrap;
  }
  .queue-item {
    margin: 15px 20px;
    padding: 15px 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 10px 10px 10px rgb(219, 234, 238);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .--queue-left {
      margin-right: 20px;
    }
    .--item-farm {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 10px;
    }
    .--item-line {
      font-size: 16px;
      font-weight: 600;
      margin-top: 10px;
    }
    .--queue-middle {
      margin: 0px 20px;
      .view-location-btn {
        cursor: pointer;
        color: white;
        background-color: #008ed1;
        border: none;
        border-radius: 5px;
        font-size: 15px;
        padding: 5px 10px;
      }
    }
    .--queue-item-error {
      color: #721c24;
      background-color: #f8d7da;
      border-color: #f5c6cb;
      position: relative;
      border: 1px solid transparent;
      border-radius: 0.25rem;
      text-align: center;
      margin: 0.2rem 1rem;
      font-weight: bold;
      margin-bottom: 15px;
    }
    .--queue-item-type {
      font-size: 20px;
      font-weight: 700;
      background-color: #eef9ed;
      color: #29b51c;
      border-radius: 10px;
      text-align: center;
      padding: 5px 15px;
      margin-bottom: 10px;
    }
    .--queue-item-author {
      font-size: 14px;
      font-weight: 500;
      margin-top: 10px;
    }
    .--queue-right {
      margin-left: 20px;
      display: flex;
      flex-direction: column;
    }
  }
}
.paper-form-page {
  display: flex;
  padding-bottom: 20px;

  .paper-form {
    flex: 1;
    padding: 20px;
    background-color: white;
    margin-right: 5px;
    border-radius: 10px;
  }
  .paper-image {
    flex: 1;
    padding-bottom: 20px;
  }
}
