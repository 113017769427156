.chart--card {
  margin-top: 15px;
  margin-bottom: 15px;
  width: 50%;
  padding-right: 10px;
  padding-left: 10px;
}
.row {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .row {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .chart--card {
    width: auto;
  }
}
.chart--title {
  text-align: center;
  font-weight: 700;
  font-size: 15px;
  margin-bottom: 5px;
}
.filter-option {
  min-width: 220px;
}
.performance-page {
  .content {
    width: 100%;
    max-width: 100%;
    border-radius: 10px;
    overflow: auto;
    background-color: white;
    border: 5px solid #fff;
  }
  .tr {
    display: flex;
    border-bottom: 1px solid #f2f2f2;
    padding: 7px 0;
    margin-top: 10px;
    width: fit-content;
    > .td:first-child {
      width: 220px;
      min-width: 220px;
    }
  }
  .td {
    flex: 1;
    width: 180px;
    min-width: 180px;
  }
  .th {
    flex: 1;
    font-weight: 700;
  }
  .--bg-gray td {
    background-color: lightgray !important;
  }
  .season-arrow-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 8px;
    border: 1px solid #f0f0f0;
    border-radius: 3px;

    div.caret {
      display: flex;
      cursor: pointer;
      padding: 5px;
      margin: -5px;
    }
    .--disabled-btn div.caret {
      cursor: not-allowed;
    }
  }

  min-height: calc(100vh - 140px);
}
.oyster-estimate-page {
  .content {
    width: 100%;
    max-width: 100%;
    border-radius: 10px;
    overflow: auto;
    background-color: white;
    border: 5px solid #fff;
  }
  .tr {
    display: flex;
    border-bottom: 1px solid #f2f2f2;
    padding: 7px 0;
    margin-top: 10px;
    width: fit-content;
    > .td:first-child {
      width: 220px;
      min-width: 220px;
    }
  }
  .td {
    flex: 1;
    width: 180px;
    min-width: 180px;
  }
  .th {
    flex: 1;
    font-weight: 700;
  }
  .ant-spin-nested-loading {
    width: 100%;
    overflow: auto;

    .ant-table {
      width: max-content;
    }
  }

  min-height: calc(100vh - 140px);
}

.stages-page {
  .stage-box {
    padding: 20px 10px;
  }
  .stage-title {
    font-weight: 700;
    color: black;
    font-size: 17px;
  }
  .stage-label {
    font-weight: 600;
    color: black;
    font-size: 15px;
  }
  .labels {
    margin: 10px 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .--size {
      color: #0c4a6e;
    }
    .--time {
      color: #4c1d95;
    }
    .--speed {
      color: #d97706;
    }
    .--length {
      color: #365314;
    }
  }
}
.sub-header {
  text-align: right;
  padding: 18px 20px;

  .header__link.active {
    border-bottom: 3px solid #1890ff;
  }
}
