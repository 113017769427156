.mt-64 {
  margin-top: 64px !important;
}
.upload-page {
  .result-summary {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
  }
  .info-box {
    border: 1px solid #c8c8c8;
    padding: 5px 10px;
    width: 150px;
  }
  .result-body {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
  .data-container {
    border: 1px solid #b3b3b3;
    margin: 10px 0px;
    display: flex;
    padding: 10px;
    background-color: #ffffff;

    .data-info {
      display: flex;
      flex-wrap: wrap;
      flex: 1.5;
    }
    .--info-item {
      margin: 10px 20px;
    }
    .message-info {
      flex: 1;
    }
    .--label {
      font-size: 14px;
      color: #7b7b7b;
    }
    .--value {
      font-size: 18px;
      color: #181b1f;
      font-weight: 700;
    }
    .--message {
      margin-top: 5px;
      color: black;
      font-size: 17px;
    }
  }
}
.timesheet-container {
  max-width: 1640px !important;
  .table .ant-table-tbody > tr > td {
    padding: 14px 5px 13px 5px;
  }
  .table .input {
    padding: 10px 10px !important;
  }
  .ant-table-cell .ant-checkbox-wrapper {
    justify-content: center;
  }
}
.d-section {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
