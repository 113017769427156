.user-messages-page {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-top: 10px;
  margin-bottom: 10px;

  .--users-list {
    flex: 1;
    text-align: center;
    background: #ffffff;
    border-radius: 12px;
    margin: 20px 10px 20px 0px;
    padding: 5px;
    min-width: 200px;
    min-height: 60vh;

    .--user-row {
      display: flex;
      margin: 8px 8px;
      padding: 7px;
      cursor: pointer;
      position: relative;

      .--user-avatar {
        display: flex;
        align-items: center;
      }
      .--user-profile {
        margin-left: 12px;

        .--user-name {
          font-size: 15px;
        }
        .--user-company {
          font-size: 12px;
          text-align: left;
        }
      }
      .--user-unread-count {
        background-color: #e91260;
        color: white;
        position: absolute;
        width: 20px;
        height: 20px;
        font-size: 14px;
        border-radius: 10px;
        right: 3px;
        font-weight: 700;
      }
    }
    .--selected-user {
      background-color: #f0f0f0;

      .--user-name {
        color: black;
        font-weight: 600;
      }
    }
  }
  .--inbox {
    flex: 5;

    .--messages {
      display: flex;
      flex-direction: column-reverse;
      max-height: 80vh;
      overflow-y: auto;

      .--message {
        transform: translateX(0px);
        padding: 5px 10px;
        margin: 0px 0px 12px 0px;

        .--created_at {
          font-size: 12px;
          color: rgb(138, 141, 145);
          font-weight: 400;
          text-overflow: ellipsis;
          text-align: right;
        }
      }
      .--sender {
        border-radius: 10px 10px 0px 10px;
        background-color: rgb(219, 241, 255);
        align-self: flex-end;
      }
      .--receiver {
        border-radius: 0px 10px 10px 10px;
        background-color: rgb(242, 246, 249);
        align-self: flex-start;
      }
    }
  }
}
