@media screen and (max-width: 768px) {
  .ant-picker-panels {
    display: flex;
    flex-direction: column;
  }
  .button--bordered.button--1 {
    margin: 0;
  }
  .mt-8 {
    margin-top: 8px !important;
  }
  .report-page .report--body {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
}
