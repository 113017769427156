.white-card {
  padding: 15px;
  margin: 20px 0px;
}
.cursor-text {
  cursor: text !important;
}
.comment {
  font-size: 12px;
  margin: 0 3px;
}
.comment-username {
  font-weight: bold;
  font-style: italic;
}
.comment-date {
  color: #111111;
  font-style: italic;
}
.delete-item {
  border: none;
  background: none;
  cursor: pointer;
}
.tasks-page {
  padding: 28px 18px;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
}
