@import '../../../styles/variables';

.table {
  padding: 16px;

  background: $white;
  border-radius: 12px;

  &__owner {
    padding-left: 6px;

    &:first-child {
      padding-left: 0px;
    }
  }

  //ant table

  & .ant-table-cell.ant-table-row-expand-icon-cell {
    padding: 0;
  }

  & .ant-table-thead > tr > th,
  & .ant-table-tbody > tr > td {
    border-bottom: 0;
  }

  &__menu-item:focus {
    outline: none;
  }

  //thead

  .ant-table-thead > tr > th {
    padding: 12px 16px;

    background: $gray-7;

    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;

    line-height: 16px;
    color: $gray-3;
  }

  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  .ant-table-container table > thead > tr:last-child th:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  .ant-table-cell {
    .tables-card__coefficient {
      position: static;
    }
  }

  //tbody

  & .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table {
    margin: 0;
  }

  & .ant-table-tbody > tr > td {
    padding: 14px 16px 13px 16px;

    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;

    line-height: 20px;
    color: $black-1;

    cursor: pointer;
  }

  & .ant-table-tbody > tr > td:first-child {
    & div[data-line='line'] {
      padding-left: 10px;
    }
  }

  & .ant-table-tbody > tr.ant-table-row:hover > td {
    background: none;
  }

  // all

  & tr.ant-table-expanded-row > td,
  tr.ant-table-expanded-row:hover > td {
    padding: 0;
  }

  &--is__child {
    padding: 0;

    & .ant-table-thead > tr > th {
      padding-top: 8px;
      padding-bottom: 8px;
    }

    & .ant-table-container table > thead > tr:first-child th:first-child {
      padding-left: 16px;
    }
  }
}
.table.ant-table-wrapper.table--isFarms,
.table.ant-table-wrapper.table--isFarm {
  overflow: auto;
}

.table--isFarms {
  $spaces: (
    2,
    4,
    6,
    8,
    10,
    12,
    14,
    16,
    18,
    20,
    22,
    24,
    26,
    28,
    30,
    32,
    34,
    36,
    38,
    40
  );

  @each $value in $spaces {
    & .ant-table-tbody tr[data-row-key$='#{$value}'] {
      background: #fafafa;
    }
  }
}

.table--isFarm,
.table--isLine {
  & .ant-table-tbody tr.ant-table-row-level-0:nth-child(2n) {
    background: #fafafa;
  }

  & .ant-table-tbody > tr > td {
    padding: 22px 16px;
  }
}
.table tr.ant-table-expanded-row > td {
  padding: 0;
}

.drag-area {
  width: 14%;

  border-left: 22px solid rgba(000, 000, 000, 0);
}

.table--lines {
  box-sizing: border-box;
  width: max-content;
  display: inline-table;

  & .ant-table-tbody tr.ant-table-row-level-0:nth-child(2n) {
    background: #fafafa;
  }
  & .ant-table-tbody > tr > td {
    padding: 22px 16px;
  }

  $spaces: (
    2,
    4,
    6,
    8,
    10,
    12,
    14,
    16,
    18,
    20,
    22,
    24,
    26,
    28,
    30,
    32,
    34,
    36,
    38,
    40
  );
  @each $value in $spaces {
    & .ant-table-tbody tr[data-row-key$='#{$value}'] {
      background: #fafafa;
    }
  }

  .ant-table-content > table {
    width: max-content;
  }
}

.drag-farm {
  width: 42%;

  & p {
    margin: 0;

    color: $black-1;
    font-size: 14px;
    line-height: 20px;
  }

  & div {
    color: $gray-3;
    font-size: 12px;
    line-height: 16px;
  }
}

.btn__modal {
  color: $brand-2;

  cursor: pointer;
}

.table .table--is__child {
  & .ant-table-tbody > tr > td {
    padding: 6px 16px 5px 16px;
  }
}

.table--isFarm .table--is__cursor .ant-table-tbody > tr > td,
.table--isLine .ant-table-tbody > tr > td {
  cursor: inherit !important;
}

.text-red {
  color: red;
}

.lines-filter-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px 0 16px;
  border-radius: 5px;
  background-color: #ffffff;

  .ant-select-selector {
    height: 48px;
  }
}

.flupsy-farm-title p,
.flupsy-farm-title span {
  color: #0505a5ba;
}
