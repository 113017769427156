@import '../../../styles/variables';

.paragrapgh {
  margin: 0;

  color: $black-1;

  &--1 {
    font-size: 16px;
    letter-spacing: -0.01em;
  }

  &--2 {
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: -0.01em;
  }

  &--3 {
    font-size: 12px;
    line-height: 1.33;
    letter-spacing: -0.01em;
  }
}