.assessment-modal {
  &__condition {
    padding-bottom: 17px;

    &-item {
      max-width: 149px;
    }
  }
  .mx-180 {
    max-width: 180px;
  }
}

@media screen and (max-width: 568px) {
  .assessment-modal {
    &__condition {
      flex-direction: column;

      padding-top: 0;

      &-item {
        padding-top: 16px;
        max-width: 100%;
      }
    }
  }
}

.farm-line-info-modal {
  margin-bottom: 30px;
  .d-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .info-card {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 5px;
    flex: 0 0 30%;
    border: 0.5px solid #cccccc2e;
  }
  .info-label {
    font-size: 15px;
    font-weight: 800;
  }
  .info-value {
    font-size: 20px;
    font-weight: 500;
  }
}
.mb-64 {
  margin-bottom: 64px;
}
.grower-harvest-modal,
.grower-seed-modal,
.section-modal {
  .section {
    margin-bottom: 30px;
    border-bottom-width: 1px;
    border-bottom-color: #898989;
    border-bottom-style: solid;
    padding-bottom: 5px;
  }
  .item-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .item-cell {
    flex: 1;
    margin: 5px 10px;
  }
  .item-cell-half {
    flex: 0.3;
    margin: 5px 10px;
  }
  .strong {
    color: black;
    font-weight: 500;
  }
  .border-bottom {
    border-bottom-width: 1px;
    border-bottom-color: #898989;
    border-bottom-style: solid;
  }
}
.wind-icon {
  img {
    width: 15px;
    filter: invert(50%) sepia(12%) saturate(3207%) hue-rotate(120deg)
      brightness(95%) contrast(80%);
  }
}
.loader {
  position: fixed;
  left: 48%;
  top: 48%;
  background: white;
  height: fit-content;
  width: fit-content;
}
.d-flex-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
